import React from "react"
import "./Pricing2.css"
import { IoMdCheckmarkCircle } from "react-icons/io/"
import { Button } from "react-bootstrap"
import Fade from "react-reveal/Fade"

const Pricing2 = () => {
  return (
    <>
      <div className="container" id="pricingDiv">
        <Fade bottom>
          <div className="pricingTextArea">
            <h1>Pricing</h1>
            <p>
              Our mission is to offer a tool which alows to effectively manage
              people, spaces and resources <br />
              providing a better experience.
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-lg">
                <div className="container">
                  <div className="basic">
                    <h1>Base</h1>
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Visual map of people in the space/areas
                    </p>
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Advanced Reporting analytics
                    </p>
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Map costumization with zones, POIs and{" "}
                      <span className="routingWord">routing</span>
                    </p>
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      WiFi Analytics
                    </p>
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Up to 3 months analytics (extended option{" "}
                      <span className="routingWord">available)</span>
                    </p>
                    <hr className="iconHr" />
                    <h3>
                      50
                      <span className="basicSpan">&nbsp;€/AP/month</span>
                    </h3>
                    <Button
                      className="basicPlanButton"
                      variant="primary"
                      href="https://app.u-find.me/trial"
                      target="_blank"
                    >
                      Start 14-day trial
                    </Button>
                  </div>
                </div>
              </div>
              <div className="col-lg">
                <div className="container">
                  <div className="advanced">
                    <h1 style={{ position: "relative", top: "1.5rem" }}>
                      Standart*
                    </h1>
                    <p style={{ paddingTop: "1.6rem" }}>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      User identification / Visual Directory
                    </p>
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Smart notifications and user/group{" "}
                      <span className="routingWord">messaging</span>
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Resources Booking with calendar integration
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      “Buddies” concept and location tracking
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Single location
                    </p>{" "}
                    <hr className="iconHr" />
                    <h3>
                      2,5
                      <span className="basicSpan">&nbsp;€/user/month</span>
                    </h3>
                    <Button
                      className="advancedPlanButton"
                      variant="primary"
                      href="#requestDemoContainer"
                    >
                      Buy Standart Plan
                    </Button>
                    <p className="includeP">
                      * Includes all "base plan" features
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg">
                <div className="container">
                  <div className="expert">
                    <h1>Advanced**</h1>
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Integration with LDAP/SAML
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Environment sensors and analytics
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Extended statistics up to 6 months
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      Multiple locations
                    </p>{" "}
                    <hr className="iconHr" />
                    <p>
                      <IoMdCheckmarkCircle className="checkIconPricing" />
                      “Things” assignment and tracking
                    </p>{" "}
                    <hr className="iconHr" />
                    <h3>
                      3,5
                      <span className="basicSpan">&nbsp;€/user/month</span>
                    </h3>
                    <Button
                      className="expertPlanButton"
                      variant="primary"
                      href="#requestDemoContainer"
                    >
                      Buy Advanced Plan
                    </Button>
                    <p className="includeP">
                      ** Includes all "standart plan" features
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="pricingTextArea">
              {" "}
              <p style={{ fontSize: "14px" }} id="pricingP">
                The pricing shown is the standard pricing, although volume based
                discounts are applicable.
                <br />
                Not all options are shown, so contact us if you have specific
                needs.
              </p>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default Pricing2
