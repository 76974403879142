import React, { useState } from "react"
import "./ServicesTechnologies.css"
import { IoIosArrowForward } from "react-icons/io"
import mapImage from "../../../images/website-optimized-images/live-map (1).png"
import mapImage2 from "../../../images/website-optimized-images/live-map-3d 2.png"
import Fade from "react-reveal"

const ServicesTechnologies = () => {
  const [showImage1, setShowImage1] = useState(true)
  const [showImage2, setShowImage2] = useState(false)
  const [showArrow1, setShowArrow1] = useState(true)
  const [showArrow2, setShowArrow2] = useState(false)

  const handleClickImage1 = () => {
    setShowImage1(true)
    setShowImage2(false)
    setShowArrow1(true)
    setShowArrow2(false)
  }
  const handleClickImage2 = () => {
    setShowImage2(true)
    setShowImage1(false)
    setShowArrow2(true)
    setShowArrow1(false)
  }

  return (
    <>
      <div className="container" id="ServicesTechnologiesDiv">
        <Fade bottom>
          <div className="textAreaServicesTechnologies">
            <h5>WiFi & Bluetooth</h5>
            <h1>Technologies</h1>
          </div>
          <div className="row" id="colParentServicesTechnologies">
            <div className="col-sm" id="boxesParentServicesTechnologies">
              <div
                className="box1ServicesTechnologies"
                id="boxOneServicesTechnologies"
                onClick={handleClickImage1}
                style={{
                  boxShadow: showImage1
                    ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                    : "none",
                }}
              >
                <h5> WiFi/BLE</h5>
                <ul>
                  <li
                    style={{ listStyleType: "none" }}
                    className="ServicesTechnologiesLi"
                  >
                    Tracking indoor people location
                  </li>
                </ul>
                <Fade right when={showArrow1} distance="110px">
                  <div className="arrowServicesTechnologies">
                    {" "}
                    <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                  </div>
                </Fade>
              </div>
              <div className="container">
                <div
                  className="box2ServicesTechnologies"
                  onClick={handleClickImage2}
                  style={{
                    boxShadow: showImage2
                      ? "0px 5px 30px rgba(183, 193, 203, 0.4)"
                      : "none",
                  }}
                >
                  <h5> Live maps</h5>
                  <ul style={{ listStyleType: "none" }}>
                    <li className="ServicesTechnologiesLi">
                      Visual 2D and 3D maps, showing live people <br />{" "}
                      information (with gdpr consent).
                    </li>
                  </ul>
                  <Fade right when={showArrow2} distance="110px">
                    <div className="arrowTechnology2">
                      {" "}
                      <IoIosArrowForward style={{ color: "#FFFFFF" }} />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
            <div
              className="col-sm"
              id="ServicesTechnologiesImgDiv1"
              style={{
                display: showImage1 ? "block" : "none",
              }}
            >
              <Fade right when={showImage1}>
                <img
                  src={mapImage}
                  alt="map"
                  className="ServicesTechnologiesImg"
                />
              </Fade>
            </div>
            <div
              className="col-sm"
              id="ServicesTechnologiesImgDiv2"
              style={{
                display: showImage2 ? "block" : "none",
              }}
            >
              <Fade right when={showImage2}>
                <img
                  src={mapImage2}
                  alt="map 2"
                  className="ServicesTechnologiesImg2"
                />
              </Fade>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ServicesTechnologies
