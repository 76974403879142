import React from "react"
import "./ServicesHero.css"
import { IoIosArrowForward } from "react-icons/io"
import { Link } from "gatsby"
import Fade from "react-reveal"

const ServicesHero = () => {
  return (
    <>
      <div className="container" id="servicesHero-container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg" id="servicesHeroTextArea1">
              <h5>Our solutions</h5>
              <h1>U-find solutions</h1>
              <p>
                We have solutions for offices and retailers/shops. We are
                working <br />
                on a shopping mall experience, hospitality and healthcare.
              </p>
            </div>
            <div className="col-lg" id="servicesHeroTextArea2">
              <Link to="/office" style={{ textDecoration: "none" }}>
                <div className="servicesHeroBox1">
                  <h5>For offices</h5>
                  <p>
                    Real-time information about office spaces, people and
                    resources <br />
                    they need for safely come to the office when needed.
                  </p>
                  <IoIosArrowForward
                    className="arrowServicesHero"
                    style={{
                      color: "#323232",
                    }}
                  />
                </div>
              </Link>
              <Link to="/shop" style={{ textDecoration: "none" }}>
                <div className="servicesHeroBox2">
                  <h5>For retailers (shops)</h5>
                  <p>
                    Real-time information about and for shop managers and
                    visitors <br />
                    to safely navigate and manage a retail shop.
                  </p>
                  <IoIosArrowForward
                    className="arrowServicesHero"
                    style={{
                      color: "#323232",
                    }}
                  />
                </div>
              </Link>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ServicesHero
