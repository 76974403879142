import React from "react"
import platform from "../../../images/website-optimized-images/platform.png"
import "./ServicesWebMobile.css"
import Fade from "react-reveal"

const ServicesWebMobile = () => {
  return (
    <div className="container-fluid" id="servicesWeb-container">
      <Fade bottom>
        <div className="textAreaServicesWeb1">
          <h5>Offices & People</h5>
          <h1>Web & mobile app</h1>
          <p>
            Our solutions for shop/office managers and end-customers/visitors.
          </p>
        </div>
        <div className="container" id="servicesWebCols-container">
          <div className="row" id="rowservicesWeb">
            <div className="col-lg order-2 order-lg-1" id="servicesWebImageDiv">
              <img
                src={platform}
                className="servicesWebImage"
                alt="ufind-servicesWebImage"
              />
            </div>
            <div
              className="col-lg order-1 order-lg-2"
              id="servicesWebTextArea2"
            >
              <div className="servicesWebList1">
                <h5> Web App</h5>
                <ul>
                  <li className="servicesWebLi">
                    Focus on user view and personal information
                  </li>
                  <li className="servicesWebLi">
                    Very Map focus on Navigation and resource allocation
                  </li>
                  <li className="servicesWebLi">
                    Quick access to personal information (spaces, bookings,
                    resources)
                  </li>
                  <li className="servicesWebLi">
                    Communication vehicle from people to people and shops/brands
                    to people
                  </li>
                </ul>
                <div className="servicesWebList2">
                  <h5>Mobile App</h5>
                  <ul>
                    <li className="servicesWebLi">Extended Reporting</li>
                    <li className="servicesWebLi">
                      Available for any profile, but extended analysis and
                      reporting for management and facilities
                    </li>
                    <li className="servicesWebLi">
                      Creation and management of maps, resources and users
                      administration
                    </li>
                    <li className="servicesWebLi">
                      Interface used for room and floor TouchPanels
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </div>
  )
}

export default ServicesWebMobile
