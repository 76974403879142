import * as React from "react"
import { useEffect } from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import ServicesHero from "../components/Services/ServicesHero/ServicesHero"
import ServicesTechnologies from "../components/Services/ServicesTechnologies/ServicesTechnologies"
import ServicesWebMobile from "../components/Services/ServicesWebMobile/ServicesWebMobile"
import ServiceWhoIsUfind from "../components/Services/ServiceWhoIsUfind/ServiceWhoIsUfind"
import RequestDemo from "../components/RequestDemo/RequestDemo"
import Scroll from "../components/ScrollToTop/Scroll"
import Pricing2 from "../components/Services/Pricing2/Pricing2"

const Services = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Layout>
        <Scroll showBelow={250} />
        <Seo title="Services" />
        <ServicesHero />
        <ServicesWebMobile />
        <ServiceWhoIsUfind />
        <ServicesTechnologies />
        <Pricing2 />
        <RequestDemo />
        {/* <Link to="/">Go back to the homepage</Link> */}
      </Layout>
    </>
  )
}

export default Services
