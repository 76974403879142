import React from "react"
import illustrations from "../../../images/website-optimized-images/illustrations-02.png"
import "./ServiceWhoIsUfind.css"
import Fade from "react-reveal"

const ServiceWhoIsUfind = () => {
  return (
    <>
      <div className="container" id="ServiceWhoIsUfind-container">
        <Fade bottom>
          <div className="row">
            <div className="col-lg" id="ServiceWhoIsUfindTextArea1">
              <h5>Why U-find</h5>
              <h1>Who is U-find for</h1>
              <p>
                Our solution is about tracking and managing places and things{" "}
                <br />
                regarding people’s needs in their everyday office/shop
                activities. With <br />
                our solution(s) we can make better and healthier spaces,
                effective use <br />
                of time and solve people’s problems in the context of their use
                of <br />
                physical space.
              </p>
            </div>
            <div className="col-lg" id="ServiceWhoIsUfindTextArea2">
              <div className="ServiceWhoIsUfindHeroBox1">
                <img
                  src={illustrations}
                  className="ServiceWhoIsUfindWebImage"
                  alt="ufind-ServiceWhoIsUfindWebImage"
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </>
  )
}

export default ServiceWhoIsUfind
